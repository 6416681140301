import React, { useEffect, useReducer } from 'react';

type ValidationPropType = {
  children: React.ReactNode;
  onInputsValid?: Function;
  onInputsInvalid?: Function;
};

type ValidationAction = {
  type: string;
  name?: string;
  payload: ValidationPayload;
};

type ValidationPayload = {
  hasTouched?: boolean;
  hasError?: boolean;
  hasValue?: boolean;
};

export type BackendValidation = {
  message: string;
  code: number;
};

export type ValidationDictionary<T> = {
  [key: string]: T;
};

export type ValidationParams = {
  active?: boolean;

  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;

  errorMessage?: string;
  validationKey?: string;

  backendValidation?: BackendValidation;
  onTouched?: Function;
};

const reducer = (
  state: { count: number; inputs: ValidationDictionary<ValidationPayload> },
  action: ValidationAction
) => {
  switch (action.type) {
    case 'register': {
      const newInputs = { ...state.inputs };
      let newCount = state.count;

      if (action.name !== undefined) {
        newInputs[action.name] = action.payload;
        newCount = state.count + 1;
      }

      return {
        count: newCount,
        inputs: { ...newInputs },
      };
    }
    case 'unregister': {
      const count = state.count - 1;
      const newInputs = { ...state.inputs };

      if (action.name !== undefined && newInputs.hasOwnProperty(action.name)) {
        delete newInputs[action.name];
      }

      return {
        count: count < 0 ? 0 : count,
        inputs: { ...newInputs },
      };
    }
    default: {
      return state;
    }
  }
};

const ValidationDispatchContext = React.createContext({});

const initialReducerState = {
  count: 0,
  inputs: {},
};

function InputValidation({
  children,
  onInputsValid,
  onInputsInvalid,
}: ValidationPropType) {
  const [{ count, inputs }, dispatch] = useReducer(
    reducer,
    initialReducerState
  );

  useEffect(() => {
    let validCount = 0;

    if (count > 0) {
      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          const payload = inputs[key];

          if (payload.hasValue && !payload.hasError) {
            validCount++;
          }
        }
      }

      if (validCount === count) {
        onInputsValid && onInputsValid();
      } else {
        onInputsInvalid && onInputsInvalid();
      }
    }
  }, [count, onInputsValid, onInputsInvalid, inputs]);

  return (
    <ValidationDispatchContext.Provider value={dispatch}>
      {children}
    </ValidationDispatchContext.Provider>
  );
}

const useInputValidation: () => React.Dispatch<ValidationAction> = () => {
  return React.useContext(
    ValidationDispatchContext
  ) as React.Dispatch<ValidationAction>;
};

export { useInputValidation, InputValidation };
