import React from 'react';
import { styled } from '../../stitches.config';

type LabelType = {
  hasError: boolean;
  children: React.ReactNode;
  css?: any;
  htmlFor?: string;
};

const Label = ({ hasError, children, css, htmlFor }: LabelType) => (
  <FormLabel htmlFor={htmlFor} css={css} color={hasError ? 'error' : 'default'}>
    {children}
  </FormLabel>
);

const FormLabel = styled('label', {
  fontSize: '14px',
  fontWeight: '$fw700',
  backgroundColor: 'inherit',
  variants: {
    color: {
      default: {
        color: 'inherit',
      },
      error: {
        color: '$errorText',
      },
    },
  },
});

export default Label;
