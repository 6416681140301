import KexLink from '../../Kex/KexLink';
import { styled } from '../../stitches.config';
import { LoadingCircle } from '../Icons';
import BaseButtonStyled from './BaseButtonStyle';
import BaseButtonType from './BaseButtonType';

type CtaButtonButtonBaseType = BaseButtonType & {
  onClick?: () => void;
  href?: string;
  css?: any;
  isLoading?: boolean;
  children?: any;
  uppercase?: boolean;
};

function CtaButton({
  onClick,
  css,
  isLoading,
  children,
  type,
  href,
  size,
  uppercase,
  ...rest
}: CtaButtonButtonBaseType) {
  return !href ? (
    <StyledButton
      color={type || 'primary'}
      css={css}
      size={size}
      onClick={() => onClick && onClick()}
      {...rest}
      uppercase={uppercase}
    >
      {isLoading ? (
        <StyledLoadingCircle>
          <LoadingCircle isLoading={true} />
        </StyledLoadingCircle>
      ) : (
        children
      )}
    </StyledButton>
  ) : (
    <StyledLink
      href={href}
      type={type}
      size={size}
      onClick={() => onClick && onClick()}
      noUnderline
      uppercase={uppercase}
      {...rest}
    >
      {isLoading ? (
        <StyledLoadingCircle>
          <LoadingCircle isLoading={true} />
        </StyledLoadingCircle>
      ) : (
        children
      )}
    </StyledLink>
  );
}
export default CtaButton;

const StyledButton = styled(BaseButtonStyled, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  py: 5.5,
  px: 6,
  fontSize: '10px',
  lineHeight: '16px',
  letterSpacing: '1px',
  fontWeight: '$fw400',
  textAlign: 'center',
  w: '100%',
  h: '40px',
  '@mediaMinSmall': {
    px: 10,
  },
  '@mediaMinLarge': {
    h: '56px',
    fontSize: '12px',
    lineHeight: '24px',
  },
  variants: {
    isLoading: {
      true: {
        backgroundColor: '$grey400',
      },
      false: {
        backgroundColor: '$buttonPrimary',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
    size: {
      s: {
        py: 2,
        fs: 7,
        lineHeight: '20px',
      },
      clearCart: {
        padding: '14px 23px 14px 23px',
        borderRadius: '40px',
        fontSize: '10px',
        fontWeight: '500',
        lineHeight: '16px',
        letterSpacing: '1px',
        height: '44px',
      },
    },
  },
});

const StyledLink = styled(KexLink, {
  backgroundColor: '$buttonPrimary',
  display: 'flex',
  justifyContent: 'center',
  fontWeight: '$fw400',
  fs: 5,
  py: 3.5,
  px: 6,
  br: 10,
  '@mediaMaxSmall': {
    lineHeight: '16px',
  },
  '@mediaMinSmall': {
    py: 4,
    px: 10,
    fs: 6,
    lineHeight: '24px',
  },
  variants: {
    type: {
      primary: {
        backgroundColor: '$buttonPrimary',
        color: '$black',
        '&:hover': {
          backgroundColor: '$buttonHoverBackgroundPrimary',
        },
        '&:disabled': {
          backgroundColor: '$buttonPrimaryDisabled',
          color: '$grey700',
          cursor: 'not-allowed',
        },
      },
      secondary: {
        backgroundColor: '$buttonSecondary',
        color: '$white',
        '&:hover': {
          backgroundColor: '$buttonHoverBackgroundSecondary',
        },
        '&:disabled': {
          backgroundColor: '$buttonSecondary',
          opacity: '0.6',
          color: '$grey700',
        },
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
  },
});

const StyledLoadingCircle = styled('div', {
  w: 9,
  my: -2,
  stroke: '$white',
});
