import { styled } from '../../stitches.config';

export const ButtonVariants = {
  variants: {
    color: {
      primary: {
        backgroundColor: '$buttonPrimary',
        color: '$black',
        '&:hover': {
          backgroundColor: '$buttonHoverBackgroundPrimary',
        },
        '&:disabled': {
          backgroundColor: '$buttonPrimaryDisabled',
          color: '$grey700',
          cursor: 'not-allowed',
        },
      },
      secondary: {
        backgroundColor: '$buttonSecondary',
        color: '$white',
        fontWeight: '$fw600',
        '&:hover': {
          backgroundColor: '$buttonHoverBackgroundSecondary',
        },
        '&:disabled': {
          backgroundColor: '$buttonSecondary',
          opacity: '0.6',
          color: '$white',
          cursor: 'not-allowed',
        },
      },
      transparent: {
        backgroundColor: 'transparent',
        color: '$buttonPrimary',
        '&:disabled': {
          backgroundColor: '$buttonPrimaryDisabled',
          color: '$grey700',
          border: '1px solid $buttonPrimary',
          cursor: 'not-allowed',
        },
      },
      accent: {
        backgroundColor: '$accent',
        color: '$textPrimary',
        borderColor: '$borderPrimary',
      },
      cancel: {
        backgroundColor: '$buttonPrimary',
        color: '$black',
        border: '1px solid $black',
        '&:hover': {
          backgroundColor: '$buttonHoverBackgroundPrimary',
        },
        '&:disabled': {
          backgroundColor: '$buttonPrimaryDisabled',
          color: '$grey700',
          cursor: 'not-allowed',
        },
      },
      clearCart: {
        backgroundColor: '$buttonSecondary',
        color: '$white',
        fontWeight: '$fw600',
        '&:hover': {
          backgroundColor: '$buttonHoverBackgroundSecondary',
        },
        '&:disabled': {
          backgroundColor: '$buttonSecondary',
          opacity: '0.6',
          color: '$white',
          cursor: 'not-allowed',
        },
      },
    },
    border: {
      true: {
        border: '1px solid $buttonPrimary',
      },
    },
    size: {
      s: {
        px: 4,
        py: 2,
        fs: 7,
        lineHeight: '20px',
      },
      m: {
        px: '40px',
        py: '16px',
      },
      l: {
        py: 6,
        px: 10,
      },
      input: {
        h: 11,
      },
    },
    loading: {
      true: {
        opacity: 0.7,
        fs: 7,
      },
    },
  },
};

export const ButtonBase = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  br: '40px',
  '&:active': {
    outlineWidth: 0,
  },
  '&:focus': {
    outlineWidth: 0,
  },
};

const BaseButtonStyled = styled('button', {
  ...ButtonBase,
  ...ButtonVariants,
  fontWeight: '$fw500',
  fontSize: '10px',
  lineHeight: '16px',
  letterSpacing: '1px',
  '@mediaMinLarge': {
    fontSize: '12px',
    lineHeight: '24px',
  },
});

export default BaseButtonStyled;
