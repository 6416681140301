import { useState } from 'react';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import ExclamationMarkIcon from '../../Atoms/Icons/ExclamationMarkIcon';
import Input from '../../Atoms/Input/Input';
import { InputValidation } from '../../Atoms/Input/InputValidation';
import { H1, H2 } from '../../Atoms/Typography/Headings/Heading';
import CreateNewPasswordPageModel from '../../CreateNewPasswordPage/Models/CreateNewPasswordPageModel.interface';
import UserCredentials from '../../LoginPage/Models/UserCredentials.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import { styled } from '../../stitches.config';
import { CreateNewPassword } from '../ForgotPasswordPage/Account';

function CreateNewPasswordPage() {
  const {
    'account/createNewPassword': createNewPasswordLabel,
    'customerInfo/newPassword': newPasswordLabel,
    'customerInfo/newPasswordConfirm': newPasswordConfirmLabel,
    'account/resetPwdSuccess': successLabel,
    'account/resetPwdError': errorLabel,
  } = useTranslationData();

  const { pageHeading, pageIntroText, createNewPasswordText, channelId } =
    useCurrentPage<CreateNewPasswordPageModel>();

  const { languageRoute } = useAppSettingsData();
  const { userName } = useUserStateData();

  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const userCredentials: UserCredentials = {
    userName: userName,
    newPassword: newPassword,
  };

  const createNewPassword = () => {
    CreateNewPassword(
      languageRoute,
      channelId,
      userCredentials,
      setIsLoading
    ).then((data) => {
      if (data && data.statusCode === 200) {
        setSuccess(true);
        setValidationMessage(successLabel);
      } else {
        setError(true);
        setValidationMessage(errorLabel);
      }
    });
  };

  const disabled =
    newPassword.length === 0 ||
    repeatNewPassword.length === 0 ||
    newPassword !== repeatNewPassword;

  return (
    <main>
      <Container>
        <InfoGridBox>
          <StyledH1 noMargin={true}>{pageHeading}</StyledH1>
          <IntroText>{pageIntroText}</IntroText>
        </InfoGridBox>
        <FormGridBox>
          <FormContainer>
            <StyledH2 noMargin={true}>{createNewPasswordLabel}</StyledH2>
            <FromDetailText>{createNewPasswordText}</FromDetailText>
            {(error || success) && (
              <ValidationMessage success={success}>
                <ExclamationMarkIcon
                  css={IconStyleError}
                  color={success ? 'white' : 'primary'}
                />
                <ErrorMessage>{validationMessage}</ErrorMessage>
              </ValidationMessage>
            )}
            <InputValidation>
              <Input
                title={newPasswordLabel}
                onChange={setNewPassword}
                type="password"
              />
              <Input
                title={newPasswordConfirmLabel}
                onChange={setRepeatNewPassword}
                type="password"
              />
            </InputValidation>
            <ButtonContainer>
              <ForgotPasswordButton
                disabled={disabled}
                onClick={createNewPassword}
                type={'secondary'}
                isLoading={isLoading}
              >
                {createNewPasswordLabel}
              </ForgotPasswordButton>
            </ButtonContainer>
          </FormContainer>
        </FormGridBox>
      </Container>
    </main>
  );
}

export default CreateNewPasswordPage;

const Container = styled('div', {
  display: 'grid',
  gg: 4,
  gridTemplateColumns: '1fr',
  wh: '100%',
  justifyContent: 'center',
  grg: 0,
  mx: 'auto',
  '@mediaMinLarge': {
    gridTemplateColumns: '2fr 3.5fr',
    gg: 0,
  },
  maxW: '$contentMaxWidth',
});

const IconStyleError = {
  wh: 4,
  mr: 2,
  mt: 1,
};

const StyledExclamationMarkIcon = styled(ExclamationMarkIcon, {
  wh: 4,
});

const GridBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  p: 8,
});

const InfoGridBox = styled(GridBox, {});

const FormGridBox = styled(GridBox, {});

const StyledH1 = styled(H1, {});

const StyledH2 = styled(H2, {
  pb: 3,
});

const IntroText = styled('p', {
  fontSize: '14px',
  lineHeight: '20px',
  ls: '1px',
});

const FormContainer = styled('div', {
  fs: 13,
  fontWeight: '$fw300',
  lineHeight: '$lh15',
  ls: '$ls125',
  pb: 16,
  '@mediaMinGreat': {
    px: 47,
    py: 6,
  },
});

const FromDetailText = styled('p', {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  mb: 6,
  mt: 0,
});

const ValidationMessage = styled('div', {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  backgroundColor: '$errorBackground',
  color: '$errorText',
  p: 6,
  my: 6,
  display: 'flex',
  variants: {
    success: {
      true: {
        backgroundColor: '$successBackground',
        color: '$successText',
      },
    },
  },
});

const ErrorMessage = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  m: 0,
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 8,
});

const ForgotPasswordButton = styled(CtaButton, {});
