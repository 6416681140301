import React, { useEffect, useState } from 'react';
import { styled } from '../../stitches.config';
import Label from '../Label/Label';
import { useInputValidation, ValidationParams } from './InputValidation';

type InputValidationType = {
  validation?: ValidationParams;
};

type InputType = InputValidationType & {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  title: string;
  type?: string;
  name?: string;
  placeholder?: string;
  round?: boolean;
  disabled?: boolean;
};

const Input = ({
  title,
  type = 'Text',
  onChange,
  onBlur,
  onKeyDown,
  name,
  validation,
  placeholder,
  round,
  disabled = false,
}: InputType) => {
  const validationDispatch = useInputValidation();

  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasTouched, setHasTouched] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(false);

  const onInputChange = (val: string) => {
    doValidation(val);
    onChange && onChange(val);
  };

  const doValidation = (currentValue: string) => {
    if (!!validation) {
      const errorMessage = validation.errorMessage || '';
      let validationError = false;
      if (validation.active !== undefined && !validation.active) {
        return;
      }

      if (validation.pattern) {
        if (!validation.pattern.test(currentValue)) {
          validationError = true;
        }
        if (validation.pattern.test(currentValue)) {
          validationError = false;
        }
      } else if (validation.required) {
        if (!currentValue) {
          validationError = true;
        }
      } else if (validation.minLength) {
        if (
          !currentValue ||
          (currentValue && currentValue.length < validation.minLength)
        ) {
          validationError = true;
        }
      } else if (validation.maxLength) {
        if (currentValue && currentValue.length > validation.maxLength) {
          validationError = true;
        }
      }

      if (validationError) {
        setHasError(true);
        setErrorMessage(errorMessage);
      } else {
        setHasError(false);
      }

      setHasValue(!!currentValue);
    }
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const currentValue: string = e.currentTarget.value;

    doValidation(currentValue);
    onBlur && onBlur(e.currentTarget.value);
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!!validation && validation.onTouched) {
      validation.onTouched(name); // TODO: Refactor? While it's okay to use callbacks since they was passed down, I don't like the fact
    }

    setHasError(false);
    setHasTouched(true);
  };

  useEffect(() => {
    validationDispatch({
      type: 'register',
      name: name,
      payload: {
        hasTouched: hasTouched,
        hasError: hasError,
        hasValue: hasValue,
      },
    });

    if (!!validation) {
      if (validation.backendValidation) {
        setHasError(true);
        setErrorMessage(validation.backendValidation.message);
      }
    }

    return () => {
      validationDispatch({ type: 'unregister', name: name, payload: {} });
    };
  }, [validationDispatch, name, hasTouched, hasError, hasValue, validation]);

  return (
    <InputGroup>
      <Label hasError={hasError}>{hasError ? errorMessage : title}</Label>
      <FormInput
        color={hasError ? 'error' : 'default'}
        type={type}
        {...(onChange && {
          onChange: (e) => onInputChange(e.currentTarget.value),
        })}
        {...(onKeyDown && { onKeyDown })}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        onInput={onInputBlur}
        name={name}
        placeholder={placeholder ? placeholder : ''}
        round={round}
        disabled={disabled}
      />
    </InputGroup>
  );
};

const InputGroup = styled('div', {
  position: 'relative',
});

const FormInput = styled('input', {
  fs: 8,
  fontWeight: '$fw700',
  ls: '$ls08',
  backgroundColor: 'inherit',
  w: '100%',
  h: 11,
  my: 2,
  px: 3,
  bw: 0.25,
  borderStyle: 'solid',
  borderColor: '$borderPrimary',
  '&:focus': {
    border: '1px solid $blue',
    outline: '1px solid $blue',
  },
  variants: {
    color: {
      default: {
        color: 'inherit',
      },
      error: {
        color: '$errorText',
      },
    },
    round: {
      true: {
        br: 2.5,
        border: '1px solid $grey100',
      },
    },
  },
  '&::placeholder': {
    color: '$grey500',
    fs: 5,
    fontWeight: '$fw400',
    '@mediaMinLarge': {
      fs: 7,
    },
  },
});

export default Input;
