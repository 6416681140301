import UserCredentials from '../../LoginPage/Models/UserCredentials.interface';

export async function ForgotPassword(
  userCredentials: UserCredentials,
  forgotPasswordPageUrl: string,
  channelId: string,
  pageId: string,
  setSuccess: (value: boolean) => void,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  setError(false);
  setValidationMessage('');
  const accountUrl = `/api/${userCredentials.language || 'en'}/account/`;

  userCredentials.channelId = channelId;

  const res = await fetch(`${accountUrl}ForgotPassword?pageId=${pageId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
    },
    body: JSON.stringify(userCredentials),
  });
  const data = await res.json();

  if (data && data.statusCode === 200) {
    setSuccess(true);
    setValidationMessage(data.message);
  } else {
    setError(true);
    setValidationMessage(data.message);
  }
  setIsLoading(false);
}

export async function CheckForgotPassword(
  language: string,
  link: string,
  channelId: string,
  createNewPasswordUrl: string,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  setError(false);
  setValidationMessage('');
  const accountUrl = `/api/${language || 'en'}/account/`;

  const res = await fetch(`${accountUrl}CheckForgotPassword?link=${link}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
    },
  });
  const data = await res.json();

  if (data && data.statusCode === 200) {
    window.location.href = `${
      createNewPasswordUrl ? createNewPasswordUrl : data.redirectUrl
    }`;
  } else {
    setError(true);
    setValidationMessage(data.message);
  }
  setIsLoading(false);
}

export async function CreateNewPassword(
  language: string,
  channelId: string,
  userCredentials: UserCredentials,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  const accountUrl = `/api/${language || 'en'}/account/`;

  const res = await fetch(`${accountUrl}CreateNewPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
    },
    body: JSON.stringify(userCredentials),
  });
  const data = await res.json();
  setIsLoading(false);

  return data;
}
